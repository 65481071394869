
import Scan from './Scan';
import WriteLanding from './Writer/index';
import { useEffect, useState } from 'react';
import { ActionsContext } from '../../contexts/context';
import AbStractNFC from '../../assets/images/absract.png'
import { useNavigate } from 'react-router-dom';
import { Row , Col, Tooltip } from 'antd';
import TemperatureIcon from '../../assets/images/temperatureCircle.png'
import HumidityIcon from '../../assets/images/humidityCircle.png'
import CO2Icon from '../../assets/images/co2Circle.png'
import co2Image from "../../assets/images/co2.png";
import o3Image from "../../assets/images/chemistry.png";
import no2Image from "../../assets/images/nitrogen.png";
import temperatureImage from "../../assets/images/celsius.png";
import pressureImage from "../../assets/images/gauge.png";
import humidityImage from "../../assets/images/humidity1.png";
import pmImage from "../../assets/images/air-pollution.png";
import coImage from "../../assets/images/gas-detector.png";
import AirImage from "../../assets/images/Air.png";
import pm1Image from "../../assets/images/pm1.0.png";
import pm10Image from "../../assets/images/pm101.png";
import VOCImage from "../../assets/images/VOOC.png";

import NH3Image from '../../assets/images/ammonia.png';
import LightImage from '../../assets/images/brightness.png'
import NoiseImage from '../../assets/images/sound.png'
import OccupancyUnoccpied from '../../assets/images/occupancy-grey.png'
import OccupancyOccupiedStatic from '../../assets/images/occupancy-yellow.png'
import OccupancyOccupiedMotiton from '../../assets/images/occupancy-green.png'
import { useSelector, useDispatch } from 'react-redux';
import {ReactComponent as Info} from  '../../assets/images/circle-info-solid.svg'


function StatusLanding05(props) {
  const lorwanState = useSelector((state) => state.scan);
  const settingState = useSelector((state) => state.lorwan);
  const resetState = useSelector((state) => state.reset);
  const dispatch = useDispatch();
  const joinType=["ABP","OTAA"];
  const workMode =["Class A","Class B","Class C"];
  const region=  ["AS923","AU915","CN470","CN799","EU433","EU868","IN865", "KR920","US915"]
  const [activeIndex,SetActiveIndex] = useState(['info','measure','sync','communication','general','sensor','calibration']);
  // const txPower= ["8dBm","10dBm", "12dBm","14dBm","16dBm","22dBm"]
  const rx2Data= {
    "0":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
    "1":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125" },
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125" },
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125" },
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500" },
          {id:"08",spread:"SF12 - DR8",name:"SF12 - BW500" },
          {id:"09",spread:"SF11 - DR9",name:"SF11 - BW500" },
          {id:"10",spread:"SF10 - DR10",name:"SF10 - BW500" },
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500" },
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500" },
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500" },
          
        ],
    "2":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF12 - DR6",name:"SF12 - BW125" },
          {id:"07",spread:"SF12 - DR7",name:"SF12 - BW125" },
        ],
      "3":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "4":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "5":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "6":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9 - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"05",spread:"SF7 - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7 - DR6",name:"SF7  - BW250" },
          {id:"07",spread:"FSK - DR7",name:"FSK" },
        ],
        "7":[
          {id:"00",spread:"SF12 - DR0",name:"SF12 - BW125" },
          {id:"01",spread:"SF11 - DR1",name:"SF11 - BW125" },
          {id:"02",spread:"SF10 - DR2",name:"SF10 - BW125" },
          {id:"03",spread:"SF9  - DR3",name:"SF9  - BW125" },
          {id:"04",spread:"SF8  - DR4",name:"SF8  - BW125" },
          {id:"05",spread:"SF7  - DR5",name:"SF7  - BW125" },
          {id:"06",spread:"SF7  - DR6",name:"SF7  - BW250" },
      ],
        "8":[
          {id:"00",spread:"SF10 - DR0",name:"SF10 - BW125"},
          {id:"01",spread:"SF9 - DR1",name:"SF9  - BW125"},
          {id:"02",spread:"SF8 - DR2",name:"SF8  - BW125"},
          {id:"03",spread:"SF7 - DR3",name:"SF7  - BW125"},
          {id:"04",spread:"SF8 - DR4",name:"SF8  - BW500"},
          {id:"08",spread:"SF12- DR8",name:"SF12 - BW500"},
          {id:"09",spread:"SF11- DR9",name:"SF11 - BW500"},
          {id:"10",spread:"SF10- DR10",name:"SF10 - BW500"},
          {id:"11",spread:"SF9 - DR11",name:"SF9 - BW500"},
          {id:"12",spread:"SF8 - DR12",name:"SF8 - BW500"},
          {id:"13",spread:"SF7 - DR13",name:"SF7 - BW500"},
        ],
  }


  useEffect(()=>{
    console.log("Setting Scan",settingState);
  },[settingState])

    useEffect(()=>{
    console.log("Status Scan",lorwanState);
  },[lorwanState])

useEffect(()=>{
    console.log("reset Scan",resetState);
  },[resetState])

 const txPower={
    "0":[
      {id:"00",name:"TXPower0-16 dBm"},
      {id:"01",name:"TXPowerl-14 dBm"},
      {id:"02",name:"TXPower2-12 dBm"},
      {id:"03",name:"TXPower3-1 0 dBm"},
      {id:"04",name:"TXPower4-8 dBm"},
      {id:"05",name:"TXPower5-6 dBm" },
      {id:"06",name:"TXPower6-4 dBm"}, 
      {id:"07",name:"TXPower7-2 dBm"},
    ],
    "6":[
      {id:"00",name:"TXPowerO-22dBm"},
      {id:"01",name:"TXPowerl-22 dBm"},
      {id:"02",name:"TXPower2-22 dBm"},
      {id:"03",name:"TXPower3-22 dBm"},
      {id:"04",name:"TXPower4-22 dBm"},
      {id:"05",name:"TXPower5-20 dBm" },
      {id:"06",name:"TXPower6-18 dBm"}, 
      {id:"07",name:"TXPower7-16 dBm"},
      {id:"08",name:"TXPower8-14 dBm"},
      {id:"09",name:"TXPower9-12dBm"},
      {id:"10",name:"TXPower10-10 dBm"}
    ]
}
  
let comMode = ['','Wifi',"LoRaWan"]

 
function addActiveHandler(loc){
  let array =[...activeIndex];
  let index = array.indexOf(loc)
  
  if(index > -1){
    array.splice(index, 1); // 2nd parameter means remove one item only
  }else{
    array.push(loc); // 2nd parameter means remove one item only
  }
  console.log("array",array,index);
  SetActiveIndex(array);
}

  return (
    <div className='StatusArea' style={{marginTop:15}}>
        <div className='container'>

            {/* <Row style={{minHeight:20}}></Row> */}

                        <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("info")} style={{cursor:"pointer"}} className='subHeaderAccordion'>
                 <span className='accordionIcon' >{activeIndex.indexOf('info')>-1?"-":"+"}</span> Device Info</h4></Col>
            </Row>
             {/* <Row gutter={16} className='bg-white'>
                <Col span={24} className='extraSpaceTitle'><p></p></Col>
             </Row> */}

            {/* <Row style={{minHeight:20}}></Row> */}
              {activeIndex.indexOf('info') > -1&&(
              <div className='subtTabAccordion'>
                                             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Serial</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'><span style={{float:"right",color:"#445ddd",fontWeight:600}}>{lorwanState.deviceEui?lorwanState.deviceEui:"-"}</span></p></Col>
             </Row> 

            <Row gutter={16} className='bg-white bg-white-header smallerFontDiv'> 

                <Col span={5}><h4>Model</h4></Col>
                <Col span={13}><h4>Signal Strength <Tooltip placement="topLeft" color={'#f4f4f4'} colorText={"#373434"} fontSize={10} lineHeight={1.2} title={
                  <div>
                    <p>Signal Strength {lorwanState.rssi}</p>
                    <p>{`Less than 5 Weak , possible NW disconnections & packet loss`}</p>
	  <p>{`Between 5 to 10 Good , Occasional packet loss `}</p>
	 <p>{`Above 10 Very Good , No packet loss`}</p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </div>
                } >
            <span style={{width:15,height:15,display:"inline-block"}}><Info/></span>
          </Tooltip></h4></Col>
                <Col span={6}><h4 style={{paddingLeft:0}}>FW Ver.</h4></Col>
                
            </Row>
            
            
             <Row gutter={16} className='bg-white smallerFontDiv'>
                <Col span={5} className='extraSpaceTitle'><p>{lorwanState.model_number?lorwanState.model_number:"-"}</p></Col>
                <Col span={13} className='extraSpaceTitle'><p>{lorwanState.rssi?parseInt(lorwanState.rssi) <= 5?<div className='networkText networkWeak'>Weak</div>: 
                parseInt(lorwanState.rssi) <= 10?<div className='networkText networkGood'>Good</div>:<div className='networkText networkVeryGood'>Very Good</div>:"-"}</p></Col>
                <Col span={6} className='extraSpaceTitle'><p>{lorwanState.firm_ver_number?lorwanState.firm_ver_number:"-"}</p></Col>
                
             </Row>
             </div>)}


            <Row style={{minHeight:20}}></Row>
                         <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("measure")} style={{cursor:"pointer"}} className='subHeaderAccordion'>Last Measured <span style={{float:"right",color:"#8a8686",fontSize:12}}>{lorwanState.reading_timestamp?new Date(lorwanState.reading_timestamp * 1000).toLocaleString() :""}</span><span className='accordionIcon' >{activeIndex.indexOf('measure')>-1?"-":"+"}</span></h4></Col>
            </Row>
          {activeIndex.indexOf("measure") > -1 &&(  
            <div className='subtTabAccordion'>
            <Row gutter={16} className='sensorList bg-white'>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIconds'><img src={TemperatureIcon} alt={"temperatureicon"}/></div>
                        <div className='sensorValue'>{lorwanState.temp &&( lorwanState.temp!=="" && lorwanState.temp!=="null"&& lorwanState.temp!==null )?Number(lorwanState.temp):"-"}°C</div>
                        <small>Temperature</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIconds'><img src={HumidityIcon} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.humidity  && ( lorwanState.humidity!=="" && lorwanState.humidity!=="null"&& lorwanState.humidity!==null )?Number(lorwanState.humidity):"-"} % Rh</div>
                        <small>Humidity</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIconds'><img src={CO2Icon} alt={"co2icon"}/></div>
                        <div className='sensorValue'>{lorwanState.co2 && ( lorwanState.co2!=="" && lorwanState.co2!=="null"&& lorwanState.co2!==null )?Number(lorwanState.co2):"-"} PPM</div>
                        <small>C02</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={pressureImage} alt={"temperatureicon"}/></div>
                        <div className='sensorValue'>{lorwanState.pressure &&( lorwanState.pressure!=="" && lorwanState.pressure!=="null"&& lorwanState.pressure!==null )?Number(lorwanState.pressure):"-"} hPA</div>
                        <small>Pressure</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                         <div className='sensorIcon'><img src={pm1Image} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.pm1  && ( lorwanState.pm1!=="" && lorwanState.pm1!=="null"&& lorwanState.pm1!==null )?Number(lorwanState.pm1):"-"} µg/m³</div>
                        <small>PM1.0</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={pmImage} alt={"co2icon"}/></div>
                        <div className='sensorValue'>{lorwanState.pm2_5 && ( lorwanState.pm2_5!=="" && lorwanState.pm2_5!=="null"&& lorwanState.pm2_5!==null )?Number(lorwanState.pm2_5):"-"} µg/m³</div>
                        <small>PM2.5</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={pm10Image} alt={"temperatureicon"}/></div>
                        <div className='sensorValue'>{lorwanState.pm10 &&( lorwanState.pm10!=="" && lorwanState.pm10!=="null"&& lorwanState.pm10!==null )?Number(lorwanState.pm10):"-"} µg/m³</div>
                        <small>PM10</small>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={VOCImage} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.tvoc  && ( lorwanState.tvoc!=="" && lorwanState.tvoc!=="null"&& lorwanState.tvoc!==null )?Number(lorwanState.tvoc):"-"} PPM</div>
                        <small>NO2</small>
                    </div>
                </Col>
                
                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={coImage} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.co  && ( lorwanState.co!=="" && lorwanState.co!=="null"&& lorwanState.co!==null )?Number(lorwanState.co):"-"} PPM</div>
                        <small>CO</small>
                    </div>
                </Col>

               <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={o3Image} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.o3  && ( lorwanState.o3!=="" && lorwanState.o3!=="null"&& lorwanState.o3!==null )?Number(lorwanState.o3):"-"} PPM</div>
                        <small>O3</small>
                    </div>
                </Col>

                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={NH3Image} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.nh3  && ( lorwanState.nh3!=="" && lorwanState.nh3!=="null"&& lorwanState.nh3!==null )?Number(lorwanState.nh3):"-"} PPM</div>
                        <small>NH3</small>
                    </div>
                </Col>


                <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={NoiseImage} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.audioDba  && ( lorwanState.audioDba!=="" && lorwanState.audioDba!=="null"&& lorwanState.audioDba!==null )?Number(lorwanState.audioDba):"-"} dBA</div>
                        <small>Noise (Avg)</small>
                    </div>
                </Col>

                 <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={NoiseImage} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.audioPeakDba  && ( lorwanState.audioPeakDba!=="" && lorwanState.audioPeakDba!=="null"&& lorwanState.audioPeakDba!==null )?Number(lorwanState.audioPeakDba):"-"} dBA</div>
                        <small>Noise (Peak)</small>
                    </div>
                </Col>

                 <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={OccupancyOccupiedMotiton} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.occupancyStatus  && ( lorwanState.occupancyStatus!=="" && lorwanState.occupancyStatus!=="null"&& lorwanState.occupancyStatus!==null )?Number(lorwanState.occupancyStatus):"-"}</div>
                        <small>Occupancy</small>
                    </div>
                </Col>

                 <Col span={8}>
                    <div className='sensorArea'>
                        <div className='sensorIcon'><img src={LightImage} alt={"humidicon"}/></div>
                        <div className='sensorValue'>{lorwanState.light  && ( lorwanState.light!=="" && lorwanState.light!=="null"&& lorwanState.light!==null )?Number(lorwanState.light):"-"} Lux</div>
                        <small>Light</small>
                    </div>
                </Col>

            </Row></div>)}

            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("sync")} className='subHeaderAccordion' style={{cursor:"pointer",margin:0}}><span className='accordionIcon' >{activeIndex.indexOf('sync')>-1?"-":"+"}</span> Last Sync Time</h4></Col>
            </Row>
            {activeIndex.indexOf("sync") > -1 &&(  
            <div className='subtTabAccordion'>
             <Row gutter={16} className='bg-white'>
                <Col span={24} className='extraSpaceTitle'><p>{lorwanState.sync_timestamp?new Date(lorwanState.sync_timestamp * 1000).toLocaleString()  :"-"}</p></Col>
             </Row>
             </div> )}


            <Row style={{minHeight:20}}></Row>

            <Row gutter={16} className='bg-white bg-white-header'> 
              <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("communication")} className='subHeaderAccordion' style={{cursor:"pointer",margin:0}}><span className='accordionIcon' >{activeIndex.indexOf('communication')>-1?"-":"+"}</span> Communication Settings</h4></Col>
            </Row>
          
            {activeIndex.indexOf('communication') > -1 && (<div className='subtTabAccordion'>
            <Row gutter={16} className='bg-white someStyling'>
                <Col span={16} className='extraSpaceTitle'><p>Communication Mode</p></Col>
                <Col span={8} className='extraSpaceTitle'><p className='lightbold txtRight' style={{color:"rgb(68, 93, 221)",fontWeight:600}}>{lorwanState.communicationMode?comMode[parseInt(lorwanState.communicationMode)] :"-"} </p></Col>
             </Row>
            
            <Row gutter={16}  className='bg-white bg-white-header'> 
                <Col span={24}><h4 onClick={()=>addActiveHandler("lorawan")} className='subHeaderAccordion'  ><span className='accordionIcon' >{activeIndex.indexOf('lorawan')>-1?"-":"+"}</span> LoRaWAN Configuration</h4></Col>
            </Row>
            {activeIndex.indexOf('lorawan') > -1 &&(<div className='subtTabAccordion'>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>LoRaWan Ver.</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.lora_ver_number?lorwanState.lora_ver_number:"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>App Port</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.appPort?parseInt(lorwanState.appPort):"-"}</p></Col>
             </Row>

            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Application Key</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight' style={{wordWrap:"break-word"}}>{lorwanState.applicationKey?lorwanState.applicationKey:"-"}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Join Type</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.joinType?joinType[lorwanState.joinType]?joinType[lorwanState.joinType]:"-":"-"}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Work Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.workMode?workMode[lorwanState.workMode]?workMode[lorwanState.workMode]:"-":"-"}</p></Col>
             </Row>

            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Region</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.region?region[lorwanState.region]?region[lorwanState.region]:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>RX2 Data Rate</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.rx2DataRate?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.rx2DataRate )?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.rx2DataRate )?.name:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Spreading Factor</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.spreadingFactor?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.spreadingFactor )?rx2Data[lorwanState.region].find(el=>el.id == lorwanState.spreadingFactor )?.spread:"-":"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Tx Power</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.txPower?txPower[lorwanState.region].find(el=>el.id===lorwanState.txPower)?txPower[lorwanState.region].find(el=>el.id===lorwanState.txPower)?.name:"-":"-"}</p></Col>
             </Row>
              
             {lorwanState.adrmode?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>ADR Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.adrmode && lorwanState.adrmode==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}
              {lorwanState.confirmed_mode?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Confirmed Mode</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.confirmed_mode && lorwanState.confirmed_mode==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}
             {lorwanState.confirmed_mode_retries && lorwanState.confirmed_mode && lorwanState.confirmed_mode==1?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Confirmed Mode Retries</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.confirmed_mode_retries && lorwanState.confirmed_mode?lorwanState.confirmed_mode_retries:"-"}</p></Col>
             </Row>:""}
              {lorwanState.data_storage?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Data Storage</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.data_storage && lorwanState.data_storage==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}


            {lorwanState.data_retransmission?
              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Data Retransmission</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.data_retransmission && lorwanState.data_retransmission==1?"Enabled":"Disabled"}</p></Col>
             </Row>:""}



            <Row style={{minHeight:20}}></Row>
</div>)}

            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24}><h4 onClick={()=>addActiveHandler("wifi")} className='subHeaderAccordion' style={{marginTop:10,cursor:"pointer"}} ><span className='accordionIcon' >{activeIndex.indexOf('wifi')>-1?"-":"+"}</span>Wifi Setting</h4></Col>
            </Row>
{activeIndex.indexOf('wifi') > -1 &&(<div className='subtTabAccordion'>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>SSID</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.ssid?lorwanState.ssid:"-"}</p></Col>
             </Row>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Password</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.password?lorwanState.password:"-"}</p></Col>
             </Row>
</div>)}
</div>)}

            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("general")} className='subHeaderAccordion' style={{marginTop:0,cursor:"pointer"}}><span className='accordionIcon' >{activeIndex.indexOf('general')>-1?"-":"+"}</span>General Setting</h4></Col>
            </Row>
            {activeIndex.indexOf('general') > -1 &&(
              <div className='subtTabAccordion'>
             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Reporting Interval</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.reportingInterval?parseInt(lorwanState.reportingInterval)+" min":" - "}</p></Col>
             </Row></div>)}
        
            <Row style={{minHeight:20}}></Row>
            <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("sensor")} className='subHeaderAccordion' style={{cursor:"pointer"}}><span className='accordionIcon' >{activeIndex.indexOf('sensor')>-1?"-":"+"}</span> Sensor Reading Offset</h4></Col>
            </Row>
            {activeIndex.indexOf('sensor')>-1 && (
<div className='subtTabAccordion'>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Temperature</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.offsetTemp?lorwanState.offsetTemp+" °C":" - "} </p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Humidity</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.offsetHumidity?lorwanState.offsetHumidity+" % Rh":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>C02</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.offsetCo2?lorwanState.offsetCo2+" PPM":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>PM1.0</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.pm1Offset?lorwanState.pm1Offset+" µg/m³":" - "} </p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>PM2.5</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.pm2_5Offset?lorwanState.pm2_5Offset+" µg/m³":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>PM10</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.pm10Offset?lorwanState.pm10Offset+" µg/m³":" - "}</p></Col>
             </Row>
                         <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Pressure</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.pressureOffset?lorwanState.pressureOffset+" hPa":" - "} </p></Col>
             </Row>

            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>CO</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.coOffset?lorwanState.coOffset+" PPM":" - "}</p></Col>
             </Row>

              <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>NO2</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.no2Offset?lorwanState.no2Offset+" PPM":" - "} </p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>O3</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.o3Offset?lorwanState.o3Offset+" PPM":" - "}</p></Col>
             </Row>
                         <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>TVOC</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.tvocOffset?lorwanState.tvocOffset+" PPM":" - "} </p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>NH3</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.nh3Offset?lorwanState.nh3Offset+" PPM":" - "}</p></Col>
             </Row>

                        <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Ambient Noise</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.noiseOffset?lorwanState.noiseOffset+" DbA":" - "}</p></Col>
             </Row> 
                             <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>LIGHT</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.luxOffset?lorwanState.luxOffset+" Lux":" - "}</p></Col>
             </Row> 
         <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>Occupancy</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.occupancyOffset?lorwanState.occupancyOffset:" - "}</p></Col>
             </Row>

</div>)}
<Row style={{minHeight:20}}></Row>

       <Row gutter={16} className='bg-white bg-white-header'> 
                <Col span={24} style={{padding:0}}><h4 onClick={()=>addActiveHandler("calibration")} className='subHeaderAccordion' style={{cursor:"pointer"}}><span className='accordionIcon' >{activeIndex.indexOf('calibration')>-1?"-":"+"}</span> Sensor Calibration </h4></Col>
            </Row>
            {activeIndex.indexOf('calibration')>-1 && (
<div className='subtTabAccordion'>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>CO RO</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.coRo?lorwanState.coRo+"":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>NO2 RO</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.no2Ro?lorwanState.no2Ro+"":" - "}</p></Col>
             </Row>
            <Row gutter={16} className='bg-white'>
                <Col span={12} className='extraSpaceTitle'><p>NH3 Ro</p></Col>
                <Col span={12} className='extraSpaceTitle'><p className='lightbold txtRight'>{lorwanState.nh3Ro?lorwanState.nh3Ro+"":" - "}</p></Col>
             </Row>
          </div>)}
           <Row style={{minHeight:50}}></Row>
            
        </div>
    </div>

  );
}

export default StatusLanding05;
