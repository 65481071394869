// App.js
import React,{useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterComponent from './common/routes'; // Adjust the path accordingly
import TopHeader from './common/TopHeader'
import { ActionsContext } from './contexts/context';
import './assets/css/common.css';

function App() {
  const [actions, setActions] = useState(null);
  const actionsValue = { actions, setActions };
  let isLoggedin= localStorage.getItem('isLoggedinSafeAirConfig')?true:false;
  return (
    <div className="App">
 <ActionsContext.Provider value={actionsValue}>
      

        {isLoggedin?<TopHeader/>:""}
        <RouterComponent />
      
    </ActionsContext.Provider>

    </div>
  );
}

export default App;
