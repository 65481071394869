import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined,MailOutlined  } from '@ant-design/icons';
import { userLogin } from './actionMethod';

// import 'antd/dist/antd.css';
import './Login.css'; // Create a CSS file for additional styling
import LoginImage from '../../assets/images/safe_text_logo.png'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import infiniteLoader from '../../assets/images/infinite_loader.gif'
import { Alert } from 'antd';
import LoginImg from '../../assets/images/login.png'

const LoginForm = (props) => {
  // Handle form submission

  const navigate = useNavigate();
  const [isLoading,setIsLoading]= useState(false);
  const [somethingwentWrongFlag, updateSomethingWrongWentFlag]= useState(false);
  const [errorMessage,updateErrorMessage] = useState('')
  const onFinish = (values) => {
    console.log('Received values:', values);

          let requestBody = {}
          requestBody.username = values.email
          requestBody.password = values.password

          userLogin(requestBody).then(res => {
            setIsLoading(true)
            if (res && res.status >= 200 && res.status <= 299) {
              //// // //console.log("Status", res.data.status)
              if (res.data && res.data.status == 200) {
                // if(res.data.data!=null) {
                //   res.data.data.lat = "17.4121531";
                //   res.data.data.lng = "78.1278513";
                // }
                localStorage.setItem('userLoggedinSafeAirConfig', JSON.stringify(res.data.data))
                localStorage.setItem('isLoggedinSafeAirConfig', true)
                // props.history.push('/dashboard');
                setTimeout(function(){setIsLoading(false) ;window.location.href="/" },1500); 
                
                // navigate('/');
              } else {
                updateSomethingWrongWentFlag(true)
                console.log("login failed",res.data.message)
                updateErrorMessage(res.data.message)
                setTimeout(function(){setIsLoading(false)},500); 
                setTimeout(() => {
                  updateSomethingWrongWentFlag(false)
                  updateErrorMessage("")
                }, 2000000);
              }
            }else{
              
              updateErrorMessage(res.data.message);
              setTimeout(function(){setIsLoading(false)},500);
                              setTimeout(() => {
                  updateSomethingWrongWentFlag(false)
                  updateErrorMessage("")
                }, 3000);
              
            }
    
            //// // //console.log("response",res);
            // localStorage.setItem('isLoggedInSafeAir', true)        
            // props.history.push('/dashboard')      
          }).catch(err => {

            updateSomethingWrongWentFlag(true)
            updateErrorMessage('Username and password do not match')
            setTimeout(function(){setIsLoading(false)},500);
                setTimeout(() => {
                  updateSomethingWrongWentFlag(false)
                  updateErrorMessage("")
                    
                }, 3000);
          })

  };

    useEffect(()=>{
    document.body.classList.add('loginReset');
    return()=>{
    document.body.classList.remove('loginReset');
    }
  },[])






  return (
    <div className="loginBG">
      <div className='container'>
      
      <div className={somethingwentWrongFlag?'loginForm error': 'loginForm'}>
        <div className='loginFormHeader'>
          <img src={LoginImg} style={{maxWidth:"250px"}} />
        </div>

      <h2>Welcome</h2>
      <p style={{color:"#a8a8a8",marginBottom:30}}><small>Login to your SafeAir account to access features</small></p>
            <Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {/* Email Field */}
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email address!' },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>

        {/* Password Field */}
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>

        

        {/* Login Button */}
          {isLoading ?<img src={infiniteLoader} /> :<Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
          {somethingwentWrongFlag?<div style={{marginTop:15}}><Alert type="error" message={errorMessage} banner /></div> :""}
        </Form.Item>}
      </Form>
       
       <p style={{color:"#000",marginBottom:30,fontSize:16}}>Need help? <span style={{color:"#394584"}} onClick={()=>navigate('/reset-password')}>Forget Password?</span></p>
      </div>
      
      </div>
     
      <div>
      
      </div>
    </div>
  );
};

export default LoginForm;